.dropBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 8px;

    .dropName {
        position: relative;
        z-index: 2;
        max-width: calc(100% - 12px);
        margin-right: 4px;
        overflow: hidden;
        color: #333;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: 0.5px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .topTriangle {
        width: 0;
        height: 0;
        border-top: 4px solid #333;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        border-top-left-radius: 1px;
    }

    .botTriangle {
        width: 0;
        height: 0;
        border-right: 4px solid transparent;
        border-bottom: 4px solid #333;
        border-left: 4px solid transparent;
        border-top-left-radius: 1px;
    }

    .left,
    .right {
        display: none;
    }

    &.dropBtnOpen {
        position: relative;
        background-color: var(--pc-base-background);
        border-radius: 8px 8px 0 0;

        .left,
        .right {
            display: block;

            &::after,
            &::before {
                position: absolute;
                bottom: 0;
                z-index: 1;
                width: 16px;
                height: 16px;
                background-color: var(--pc-base-background);
                content: '';
            }
        }

        .left {
            &::after,
            &::before {
                left: -16px;
                border-radius: 8px 8px 0;
            }

            &::after {
                z-index: 2;
                background-color: #f7f7f8;
                border-radius: 8px;
            }
        }

        .right {
            &::after,
            &::before {
                right: -16px;
                border-radius: 8px 8px 8px 0;
            }

            &::after {
                z-index: 2;
                background-color: #f7f7f8;
                border-radius: 8px;
            }
        }

        &.dropBtnLeft {
            .left {
                display: none;
            }
        }

        &.dropBtnRight {
            .right {
                display: none;
            }
        }
    }
}

.dropMenu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    background-color: rgba(0, 0, 0, 0.5);

    .dropList {
        display: flex;
        overflow: hidden;
        border-radius: 0 0 8px 8px;
    }

    ul.list {
        flex: 1;
        padding-bottom: 14px;
        background-color: var(--pc-base-background);
    }

    ul.list li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        padding: 0 24px;
        color: #333;
        line-height: 1.57;
        letter-spacing: 0.5px;

        &.active {
            color: var(--pc-primary-color);
        }

        .icon {
            color: var(--pc-primary-color);
            font-weight: 500;
            font-family: PingFangSC-Medium;
            font-size: 18px;
        }
    }

    ul.muitl {
        flex: none;
        width: 30%;
        background-color: #f7f7f8;

        li {
            &.active {
                color: var(--pc-primary-color);
                background-color: var(--pc-base-background);
            }
        }
    }

    ul.mts {
        width: 50%;
    }

    .dropModal {
        flex: 1;
    }
}

.searchBtn {
    height: 26px;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    .botTriangle {
        border-bottom: 4px solid var(--theme-color);
    }

    &.dropBtnOpen {
        position: relative;
        background-color: transparent;
        // background-color: var(--pc-base-background);
        // border-radius: 8px 8px 0 0;

        .dropName {
            color: var(--theme-color);
        }

        .left,
        .right {
            display: none;
        }
    }
}
