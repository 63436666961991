.analyst {
    width: 100%;
    position: relative;
    padding: 0 16px 24px;
    box-sizing: border-box;
}

.analystList li {
    display: block;
    height: 92px;
    padding: 8px 16px 12px;
    margin-bottom: 12px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 8px;
    background-color: var(--pc-base-background);

    // &:hover {
    //     background-color: #f7f7f8;
    // }

    // &.active {
    //     background-color: #ebebeb;
    // }

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        letter-spacing: 0.5px;

        h2 {
            width: 100%;
            font-size: var(--pc-font-size-16);
            font-weight: normal;
            line-height: var(--pc-line-height-a);
            color: #333;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .symbol {
        display: flex;
        align-items: center;
        margin-top: 4px;
        letter-spacing: 0.5px;

        .symbolSign {
            margin-right: 8px;
            font-size: 14px;
            line-height: 1.57;
            color: #666;
        }

        .fluctuate {
            display: flex;
            align-items: center;
            line-height: 1;
            font-size: 12px;

            :global {
                .anticon {
                    font-size: 18px;
                }
            }

            .fName {
                margin: 0 8px;
            }
        }
    }

    .summary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;

        span {
            &:first-child {
                width: calc(100% - 60px);
                font-size: 12px;
                color: #999;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            &:last-child {
                width: 60px;
                text-align: right;
                font-size: 12px;
                color: #999;
            }
        }
    }
}