.searchItems {
    padding-top: 8px;
    position: relative;
    background-color: #f7f7f8;

    .form {
        width: 100%;
        display: flex;
        position: relative;
    }

    :global {
        .ant-form-item {
            margin-bottom: 0;
        }

        .ant-form-item-control,
        .ant-form-item-control-input {
            position: static !important;
        }
    }
}