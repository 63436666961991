.filterList {
    // position: sticky;
    // top: 100px;
    display: flex;
    align-items: center;
    // z-index: 999;

    .filterItem {
        width: 70px;
        height: 32px;
        margin-right: 8px;
        border-radius: 4px;
        border: solid 1px #d9d9de;
        text-align: center;
        line-height: 30px;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: var(--pc-visited-color);
        cursor: pointer;
        box-sizing: border-box;
    }

    .filterItemEn:nth-of-type(3) {
        width: 80px;
    }

    .timerLi {
        width: calc(100% - 234px);
    }

    .timer {
        width: 100%;
        padding: 4px 12px;

        input {
            letter-spacing: 0.5px;
        }
    }

    .activeItem {
        border-color: var(--pc-primary-color);
        color: #333333;

        .timer {
            color: #333333;
            border-color: var(--pc-primary-color);
        }
    }
}
