.loadMore {
    padding: 24px 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // cursor: pointer;
    text-align: center;
    position: relative;
    box-sizing: border-box;

    span {
        font-size: var(--pc-font-size-14);
        color: var(--pc-normal-color);
        letter-spacing: 0.44px;
        -webkit-user-select: none;
        user-select: none
    }

    ol {
        display: flex;
        list-style: none;
        @animateTime: 0.45s;
        margin-left: 5px;
        opacity: 0;

        li {
            width: 4px;
            height: 4px;
            margin: 0 6px;
            border-radius: 50%;
            background-color: var(--pc-primary-color);
            animation: scale @animateTime infinite alternate;
        }

        li:nth-child(2) {
            animation-delay: @animateTime * (1 / 3);
        }

        li:nth-child(3) {
            animation-delay: @animateTime * (2 / 3);
        }
    }

    @keyframes scale {
        from {
            transform: scale(1);
        }

        to {
            transform: scale(1.75);
        }
    }
}

.loadMoreLoading {
    span {
        color: var(--pc-primary-color);
    }

    ol {
        opacity: 1;
    }
}

.loadMoreLoaded {
    span {
        color: var(--pc-base-f1-color);
    }
}

.error {
    cursor: pointer;
    text-align: center;
    font-size: var(--pc-font-size-14);
    color: var(--pc-normal-color);
}

.empty {
    letter-spacing: 0.5px;
    font-size: var(--pc-font-size-14);
    color: var(--pc-visited-color);
}

.pcLoadMore {
    box-sizing: border-box;
    width: 292px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    background-color: #fff;
    color: #262b2e;
    font-size: 14px;
    letter-spacing: 0.2px;
    cursor: pointer;

    &:hover {
        color: var(--pc-primary-color);
    }
}

@media screen and (max-width: 768px) {
    .loadMore {
        padding-left: 0;
        margin: 24px auto 0;

        &::before {
            display: none;
        }
    }
}

@media screen and (max-width: 628px) {
    .empty {
        padding: 0 16px;
        margin-top: 45px;
        text-align: center;

        img {
            width: 100%;
        }

        span {
            margin-top: 16px;
            white-space: nowrap;
        }
    }
}